@import url(/assets/simple.css);

@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"),
    url("/assets/fonts/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Bold.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Bold.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"),
    url("/assets/fonts/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-BoldItalic.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-BoldItalic.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"),
    url("/assets/fonts/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Italic.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Italic.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"),
    url("/assets/fonts/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Regular.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Regular.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
    url("/assets/fonts/IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Bold.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Bold.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"),
    url("/assets/fonts/IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-BoldItalic.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-BoldItalic.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"),
    url("/assets/fonts/IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Italic.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Italic.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"),
    url("/assets/fonts/IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Regular.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Regular.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Serif Bold"), local("IBMPlexSerif-Bold"),
    url("/assets/fonts/IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Bold.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Bold.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Serif Bold Italic"), local("IBMPlexSerif-BoldItalic"),
    url("/assets/fonts/IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-BoldItalic.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-BoldItalic.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"),
    url("/assets/fonts/IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Italic.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Italic.woff")
      format("woff");
}
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"),
    url("/assets/fonts/IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Regular.woff2")
      format("woff2"),
    url("/assets/fonts/IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Regular.woff")
      format("woff");
}

:root {
  --sans-font: "IBM Plex Sans", sans-serif;
  --serif-font: "IBM Plex Serif", serif;
  --mono-font: "IBM Plex Mono", monospace;
  --accent: #8A425D;
  --accent-bg: #8A425D33;
}

html {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

body > header {
  --text: white;
  padding: 16px;
  background-image: url("/assets/header-bg.png");
  background-size: cover;
  padding-left: max(env(safe-area-inset-left), 16px);
  padding-top: max(env(safe-area-inset-top), 16px);
  padding-right: max(env(safe-area-inset-right), 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

#site-logo {
  height: 32px;
  width: 32px;
}

#site-title {
  display: flex;
  gap: 16px;
  font-size: 32px;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  text-align: left;
  color: var(--text);
}

#site-nav > a{
  margin-bottom: 0;
  font-weight: 600;
}
#site-nav {
  padding: 0;
}

body {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

body > footer {
  padding-bottom: max(env(safe-area-inset-bottom), 8px);
}

main {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  font-family: var(--serif-font);
}

main > header {
  border-bottom: solid 1px var(--accent);
  margin-bottom: 32px;
}

.alt {
  font-family: var(--sans-font);
  color: var(--text-light);
}

.listitem {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  align-items: stretch;
  border-top: solid 1px var(--border);
}

@media screen and (min-width: 768px) {
  .listitem {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
    grid-template-rows: auto auto auto 1fr;
    align-items: start;
  }

  .listitemimage {
    grid-row: 1/-1;
    grid-column: 2;
    max-height: 200px;
    max-width: 200px;
  }
}

.listitem > p {
  margin: 0;
}

.listitem > h2 {
  margin: 0;
}

.listitem > h2 > a {
  text-decoration: none;
  color: var(--text);
}

.listitem > h2 > a:hover {
  text-decoration: none;
  color: var(--text-light);
}

textarea {
  width: 100%;
  resize: none;
  font-family: var(--mono-font);
}

.wip::after {
  content: "WIP";
  vertical-align: top;
  font-size: 16px;
  font-family: var(--mono-font);
  background-color: var(--accent-bg);
  border: solid 1px var(--accent);
  color: var(--accent);
  border-radius: 4px;
  padding: 4px;
}